<template>
    <v-container>
        <base-material-card icon="fas fa-credit-card" title="Cuentas" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'CuentaCreate'})">Nuevo</v-btn>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="cuentas" :loading="loading" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.accciones="{item}">
                            <v-btn fab icon x-small @click="$router.push({name: 'CuentaEdit',params: {id: item.id}})">
                                <v-icon>far fa-edit</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.last_page" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Banco', value: 'banco', sortable: false },
                { text: 'Razón social', value: 'razon_social', sortable: false },
                { text: 'Número', value: 'numero', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            cuentas: [],
            pagination: {
                per_page: 10,
                last_page: 1,
                page: 1
            },
            loading: false
        }),
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true
                this.$http.get('/cuentas/', {
                    params: this.pagination
                }).then(response => {
                    this.cuentas = response.data.data
                    this.pagination.last_page = response.data.last_page
                    this.loading = false
                })
            }
        }
    }
</script>
